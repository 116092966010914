var $ = require('jquery');

$(document).on("turbolinks:load", function() {
  $('.user-info').click(function(e){
    $('body').toggleClass('open-sidebar');
  });

  setTimeout(function() {
    $('#notice').fadeOut();
   }, 3000);

   var datefield = document.createElement("input")

    datefield.setAttribute("type", "date")
       
    if (datefield.type != "date"){ //if browser doesn't support input type="date", initialize date picker widget:
      $( ".datepicker" ).datepicker({ dateFormat: 'mm/dd/yy' });
    } 

});

